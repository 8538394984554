<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code>
                <validation-observer ref="simpleRules">
                  <form @submit.prevent="submitForm">
                    <b-row>
                      <b-col md="4">
                        <b-form-group>
                          <label>Select Date</label>
                          <flat-pickr
                            class="form-control"
                            :config="{
                              dateFormat: 'd/m/Y',
                              mode: 'range',
                            }"
                            style="background-color: transparent"
                            placeholder="Select Date"
                            @input="getdata($event)"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Status</label>
                          <v-select
                            v-model="status"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="name"
                            placeholder="None"
                            :options="['Ledger', 'Reconcile']"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Bank</label>
                          <v-select
                            v-model="bank"
                            label="bankname"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            placeholder="None"
                            :options="bankoption"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="d-flex justify-content-end">
                      <b-button
                        variant="primary"
                        class="ml-2"
                        @click="searchData"
                      >
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span class="align-middle">Search</span>
                      </b-button>
                    </div>
                  </form>
                </validation-observer>
              </b-card-code>
              <report-table :data="data" :columns="fields" />
              <table
                class="table table-bordered mt-1"
                style="color: black; font-weight: bolder"
                v-if="visible"
              >
                <thead>
                  <tr>
                    <td>Ledger Balance as per company Books</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Amount not reflected in Bank</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Ledger Balance as per Bank</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportTable from "../../../components/ReportTable.vue";
import axios from '@/components/axios';
import moment from "moment";

import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Form from "@/components/form/Form.vue";
import { BFormGroup, BForm, BRow, BCol, BButton } from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
import vSelect from "vue-select";
export default {
  components: {
    ReportTable,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    GoodTableColumnSearch,
  },
  data() {
    return {
      startdate: "",
      enddate: "",
      result: "",
      status: "",
      bank: "",
      bankoption: [],
      data: "",
      totalCr: 0,
      totalDr: 0,
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      visible: false,
      fields: [
        {
          field: "branch",
          label: "Branch",
        },
        {
          field: "enterby",
          label: "Enter By",
        },
        {
          field: "voucher_datetime",
          label: "Date",
        },
        {
          field: "no",
          label: "No",
        },
        {
          field: "oppledger_name",
          label: "Particulars",
        },
        {
          field: "voucher_type",
          label: "Type",
        },
        {
          field: "chequeno",
          label: "Cheque No",
        },
        {
          field: "debit",
          label: "Dr Amount",
          tdClass: "text-right",
        },
        {
          field: "credit",
          label: "Cr Amount",
          tdClass: "text-right",
        },
        {
          field: "balance",
          label: "Balance",
          tdClass: "text-right w-100",
        },
        {
          field: "reconciliationdate",
          label: "Reconciliation Date",
        },
      ],
    };
  },
  mounted() {
    this.getBank();
  },
  methods: {
    getdata(selectedDates) {
      this.result = selectedDates.split(" to ");
      this.startdate = this.result[0];
      this.enddate = this.result[1];
    },
    async searchData() {
      const data = {
        startdate: this.startdate,
        enddate: this.enddate,
        status: this.status,
        bank: this.bank.id,
      };
      await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify(data),
        url: `${this.baseApi}/bankreconciliation`,
      }).then((response) => {
        this.data = response.data.data;
        if (this.data.length > 0) {
          const text = {
            oppledger_name: "Opening Balance",
            credit:
              this.data[0].op_balance_crdr == "Cr."
                ? this.data[0].op_balance
                : 0,
            debit:
              this.data[0].op_balance_crdr == "Dr."
                ? this.data[0].op_balance
                : 0,
            amount: parseFloat(this.data[0].op_balance),
            balance:
              this.data[0].op_balance_crdr == "Cr."
                ? parseFloat(this.data[0].op_balance) + " " + "Cr."
                : parseFloat(this.data[0].op_balance) + " " + "Dr.",
          };

          let arr = this.data[0].ledgervoucher;
          this.data[0].ledgervoucher = [];
          this.data[0].ledgervoucher.push(text);

          arr.map((item, index) => {
            this.data[0].ledgervoucher[index + 1] = item;

            if (parseFloat(this.data[0].ledgervoucher[index + 1].debit) == 0) {
              this.totalCr += parseFloat(item.credit);
              this.data[0].ledgervoucher[index + 1].amount =
                this.data[0].ledgervoucher[index].amount +
                parseFloat(this.data[0].ledgervoucher[index + 1].credit, 3);
            } else {
              this.totalDr += parseFloat(item.debit);
              this.data[0].ledgervoucher[index + 1].amount =
                this.data[0].ledgervoucher[index].amount -
                parseFloat(this.data[0].ledgervoucher[index + 1].debit, 3);
            }

            if (this.data[0].ledgervoucher[index + 1].amount < 0) {
              this.data[0].ledgervoucher[index + 1].balance =
                (
                  this.data[0].ledgervoucher[index + 1].amount * -1
                ).toLocaleString("en-IN") +
                " " +
                "Dr.";
            } else {
              this.data[0].ledgervoucher[index + 1].balance =
                this.data[0].ledgervoucher[index + 1].amount.toLocaleString(
                  "en-IN"
                ) +
                " " +
                "Cr.";
            }
            this.data[0].ledgervoucher[index + 1].voucher_datetime = moment(
              item.voucher_datetime.split(" ")[0]
            ).format("DD/MM/yyyy");
            this.data[0].ledgervoucher[index + 1].debit = parseFloat(
              item.debit
            ).toLocaleString("en-IN");
            this.data[0].ledgervoucher[index + 1].credit = parseFloat(
              item.credit
            ).toLocaleString("en-IN");
            this.visible = true;
          });

          //
          this.data = this.data[0].ledgervoucher;
        }
      });
    },

    async getBank() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/bank`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.bankoption = response.data.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.amount {
  text-align: end;
}
</style>
